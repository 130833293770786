.modalDescriptionText {
  color: black;
  margin-bottom: 15px;
  text-align: center;
}

.skillsContainer {
  margin-bottom: 75px;
}

.skillsGridContainer {
  max-width: 80vw;
}

.skillsDescription {
  text-align: center;
  margin-bottom: 35px;
}

.skillButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgb(255, 255, 255, 0.1);
  border-radius: 25px;
  transition: background-color ease 600ms;
  border-width: 0;
  cursor: pointer;
}

.skillButton:hover {
  background-color: rgb(255, 255, 255, 0.2);
}

.skillContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.skillIcon {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 5rem;
  transform: scale(1);
  padding-bottom: 15px;
  transition: transform 600ms ease;
}

.skillButton:hover .skillIcon {
  transform: scale(1.2);
}

.skillHTML5 {
  color: #ff6600;
}

.skillCSS3 {
  color: #264de4;
}

.skillShopify {
  color: #96bf48;
}

.skillJavascript {
  color: #f0db4f;
}

.skillCommunicatingwithClients {
  color: white;
}

.active.skillCommunicatingwithClients {
  color: black;
}

.skillSEO {
  color: black;
}

.skillReact {
  color: #61dbfb;
}

.skillPHP {
  color: #8993be;
}

.skillMySQL {
  color: #00758f;
}

.skillSCSS {
  color: #cc6699;
}

.skillGit {
  color: #f34f29;
}

.skillNodeJS {
  color: #3c873a;
}

.skillPhotoshop {
  color: #8bc3fc;
}

.skillMongoDB {
  color: #4db33d;
}

.ReactModal__Content {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 100000;
  max-width: 60vw;
}

.modalContainer {
}

@media only screen and (max-width: 600px) {
  .skillsGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    row-gap: 5px;
  }
  .skillButton {
    min-width: 170px;
    min-height: 180px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .skillsGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 15px;
    column-gap: 15px;
  }
  .skillButton {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1000px) {
  .skillsGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 15px;
    column-gap: 15px;
  }
  .skillButton {
    min-width: 300px;
  }
}
