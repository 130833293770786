:root {
  font-size: 18px;
  font-family: "Open Sans";
  --text-primary: coral;
  --text-secondary: #aaffbb;
  --bg-primary: #fa00bc;
  --bg-secondary: #6532ab;
}

body {
  color: white;
  background-color: white;
  margin: auto;
  padding: 0;
  background: rgb(194, 223, 227);
  font-family: "Hind Siliguri", sans-serif;
}

a {
  text-decoration: none;
  color: var(--text-primary);
}

main {
  width: 80vw;
  margin: auto;
  padding: 1rem;
}

p.aboutText {
  background: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 1);
  padding: 10px;
  border-radius: 0.69rem;
  text-align: center;
  z-index: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary);
  padding: 5px 0;
  margin: 0 0 25px 0;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  main {
    margin-bottom: 10vh;
  }
}

@media only screen and (min-width: 600px) {
  main {
    margin: 0 auto;
  }
  body {
    margin-left: 7rem;
  }
}
