.StaticBackground {
  background-color: #240046;
  color: #fff;
  width: 100vw;
  height: 100vh;
  display: block;
  text-align: center;
  line-height: 40px;
  position: fixed;
  z-index: -10;
  overflow: hidden;
}
.StaticBackground:after {
  content: "";
  position: absolute;
  background-color: #3c096c;
  width: 1070vw;
  height: 500vh;
  border-radius: 75%;
  top: 0;
  left: 0;
  transform: translate(-62%, 4%);
  box-shadow: rgba(16, 0, 43) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.1) 0px 18px 36px -18px inset;
  z-index: -20;
}
