.contactContainer {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  padding: 15px 20px 25px 20px;
}

.contactTextContainer {
  text-align: center;
}

.contactFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.contactEmailContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .contactEmail {
    width: 33.3%;
  }
  .contactMessage {
    width: 66.7%;
  }
}

@media screen and (max-width: 600px) {
  .contactEmail {
    width: 100%;
  }
  .contactMessage {
    width: 100%;
  }
}

.contactEmail {
  height: 1rem;
  border-radius: 0.3rem;
  border: none;
  padding: 0.5rem;
  font-family: "Hind Siliguri", sans-serif;
}

.contactMessageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactMessage {
  max-width: 66vw;
  max-height: 100vh;
  height: 10rem;
  border-radius: 1rem;
  border: none;
  padding: 15px;
  font-family: "Hind Siliguri", sans-serif;
  background-color: rgba(255, 255, 255);
}

.contactButton {
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  border-radius: 0.5rem;
  border: none;
  background-color: #240046;
  color: coral;
  width: fit-content;
  padding: 0.5rem 2rem;
}

.contactIcon {
  height: 5rem;
  width: 5rem;
  margin: 25px 15px;
  transform: scale(0.9);
  transition: ease 600ms;
}

.iconLinkedIn {
  color: #0077b5;
}

.iconGitHub {
  color: white;
}

.contactIcon:hover {
  transform: scale(1);
}
